import * as React from "react";
import { BetBlock } from "../BetBlock/BetBlock";
import { ChipsStack } from "../BetBlock/ChipsStack";
import { ButtonBlock, Buttons } from "../ButtonBlock/ButtonBlock";
import { Card, SuitRank } from "../Cards/Card";
import { CardBlock } from "../Cards/CardBlock";
import { Connector } from "../Cards/Connector";
import { Deck } from "../Cards/Deck";
import { HandResolution, PokerHand, PokerRank } from "../Cards/PokerHand";
import { Banker } from "./Banker";
import { HandDescription } from "./HandDescription";
import "./Home.css";
import { Logo } from "./Logo";
import { Menu } from "./Menu";
import { PayMaster, PayOuts } from "./PayMaster";
import { ResolutionDescription } from "./ResolutionDescription";
import { Settings } from "./Settings";
import { StrategyBlock } from "./StrategyBlock";

export enum GameState {
  NoBet,
  PlayerCardsDealt,
  FlopShown,
  RiverShown,
  DealerShown
}

export enum TheBets {
  ante,
  blind,
  trips,
  play
}

export class Bets {
  ante: number = 0;
  blind: number = 0;
  play: number = 0;
  trips: number = 0;

  clear() {
    this.ante = 0;
    this.blind = 0;
    this.play = 0;
    this.trips = 0;
  }

  get total(): number {
    return this.ante + this.blind + this.play + this.trips;
  }
}

class HomeState {
  animating: boolean = false;
  bets: Bets = new Bets();
  dealerCards: SuitRank[] = [];
  dealerPokerRank: PokerRank[] = null;
  flopCards: SuitRank[] = [];
  folded: boolean = false;
  gameState: GameState = GameState.NoBet;
  payouts: PayOuts = null;
  playerCards: SuitRank[] = [];
  playerPokerRank: PokerRank[] = null;
  resolution: HandResolution = null;
  riverCards: SuitRank[] = [];
  deckCards: SuitRank[] = [];
  settingsChangeCount: number = 0;
  showing: boolean = false;

  constructor() {
    const deck = new Deck();
    deck.shuffle();

    //  get the cards
    deck.burnOne();
    this.playerCards = deck.take(2);
    deck.burnOne();
    this.dealerCards = deck.take(2);
    deck.burnOne();
    this.flopCards = deck.take(3);
    deck.burnOne();
    this.riverCards = deck.take(2);
    this.deckCards = deck.take(1);
  }
}

export class Home extends React.Component<{}, HomeState> {
  private ph: PokerHand;
  private bank: Banker;
  private pm: PayMaster;
  private lastAnte: number = 0;
  private lastTrips: number = 0;
  private settings: Settings;

  constructor(props: any) {
    super(props);
    this.state = new HomeState();
    this.ph = new PokerHand();
    this.bank = new Banker();
    this.pm = new PayMaster();
    this.settings = new Settings(() => this.setState({ settingsChangeCount: this.state.settingsChangeCount + 1 }));
  }

  componentDidMount() {
    //  force reposition on resize
    window.addEventListener("resize", this.onResize);
  }

  private resizeTimerId: number = null;
  private onResize = () => {
    window.clearTimeout(this.resizeTimerId);
    this.resizeTimerId = window.setTimeout(this.resizeHome, 100);
  };

  private processGameStateEvents = (previousState: GameState): void => {
    if (previousState === GameState.NoBet) {
      this.lastAnte = this.state.bets.ante;
      this.lastTrips = this.state.bets.trips;
    }

    if (this.state.gameState >= GameState.PlayerCardsDealt && this.state.gameState <= GameState.DealerShown) {
      const playerPokerRank = this.ph.rankHand(
        this.state.playerCards,
        this.state.gameState >= GameState.FlopShown ? this.state.flopCards : [],
        this.state.gameState >= GameState.RiverShown ? this.state.riverCards : []
      );

      if (this.state.gameState === GameState.DealerShown) {
        this.resolveHand(playerPokerRank);
      } else {
        this.setState({ playerPokerRank: playerPokerRank });
      }
    }
  };

  private advanceGameState = (nextState: GameState): void => {
    if (nextState < this.state.gameState || nextState === this.state.gameState + 1) {
      const prevState = this.state.gameState;
      this.setState({ gameState: nextState, animating: false }, () => this.processGameStateEvents(prevState));
    } else {
      this.setState({ animating: true }, () => {
        window.setTimeout(() => {
          const prevState = this.state.gameState;
          this.setState({ gameState: this.state.gameState + 1 }, () => {
            this.processGameStateEvents(prevState);
            window.setTimeout(() => this.advanceGameState(nextState), 2000);
          });
        }, 300);
      });
    }
  };

  private raiseTrips = (raise: number): void => {
    const bets = this.state.bets;
    bets.trips += this.bank.payOut(raise);
    this.setState({ bets: bets });
  };

  private raiseAnteAndBlind = (raise: number): void => {
    const bets = this.state.bets;
    bets.ante += this.bank.payOut(raise);
    bets.blind += this.bank.payOut(raise);
    this.setState({ bets: bets });
  };

  private betPlay = (multiple: number): void => {
    const bets = this.state.bets;
    bets.play = this.bank.payOut(bets.ante * multiple);
    this.setState({ bets: bets });
  };

  private clearBet = (which: TheBets): void => {
    if (this.state.gameState === GameState.NoBet) {
      const bets = this.state.bets;

      switch (which) {
        case TheBets.ante:
        case TheBets.blind:
          this.bank.payIn(bets.ante + bets.blind);
          bets.ante = bets.blind = 0;
          break;
        case TheBets.trips:
          this.bank.payIn(bets.trips);
          bets.trips = 0;
          break;
      }

      this.setState({ bets: bets });
    }
  };

  private newHand = (): void => {
    const hs = new HomeState();
    hs.showing = true;
    hs.bets.trips = this.bank.payOut(this.lastTrips);
    hs.bets.ante = this.bank.payOut(this.lastAnte);
    hs.bets.blind = this.bank.payOut(this.lastAnte);
    this.setState(hs);
  };

  private fold = (): void => {
    this.setState({ folded: true }, () => this.advanceGameState(GameState.DealerShown));
  };

  private resolveHand = (playerPokerRank: PokerRank[]): void => {
    const bets = this.state.bets;
    const dealerPokerRank = this.ph.rankHand(this.state.dealerCards, this.state.flopCards, this.state.riverCards);
    const resolution = this.ph.resolveHands(dealerPokerRank, playerPokerRank, this.state.folded);
    const payouts = this.pm.computePayouts(this.state.bets, resolution);

    // console.log("Bets:");
    // console.log(bets);
    // console.log("Payouts:");
    // console.log(payouts);

    // console.log(`Bank balance before payout: ${this.bank.balance}`);

    if (payouts.trips < 0) bets.trips = 0;
    else this.bank.payIn(bets.trips + payouts.trips);

    if (payouts.ante < 0) bets.ante = 0;
    else this.bank.payIn(bets.ante + payouts.ante);

    if (payouts.blind < 0) bets.blind = 0;
    else this.bank.payIn(bets.blind + payouts.blind);

    if (payouts.play < 0) bets.play = 0;
    else this.bank.payIn(bets.play + payouts.play);

    // console.log(`Bank balance after payout: ${this.bank.balance}`);

    this.bank.saveToStorage();

    this.setState({
      bets: bets,
      payouts: payouts,
      dealerPokerRank: dealerPokerRank,
      resolution: resolution
    });
  };

  private strategyBet = (): boolean => {
    switch (this.state.gameState) {
      case GameState.PlayerCardsDealt:
        return this.ph.makeLargeRaise(this.state.playerCards);
      case GameState.FlopShown:
        return this.ph.makeMediumRaise(this.state.playerCards, this.state.flopCards);
      case GameState.RiverShown:
        return this.ph.makeSmallRaise(this.state.playerCards, this.state.flopCards, this.state.riverCards, this.state.folded);
    }

    return false;
  };

  private computeDefaultButton = (): Buttons => {
    switch (this.state.gameState) {
      case GameState.NoBet:
        if (!this.state.bets.ante) return Buttons.BetPlus5;
        if (!this.state.bets.trips) return Buttons.TripsPlus5;
        return Buttons.Deal;
      case GameState.PlayerCardsDealt:
      case GameState.FlopShown:
      case GameState.RiverShown:
        if (!this.settings.showSuggestions) return null;
        if (this.strategyBet()) return Buttons.Bet;
        return Buttons.CheckOrFold;
      case GameState.DealerShown:
        return Buttons.NextHand;
    }

    return Buttons.Deal;
  };

  private get offerStrategy(): boolean {
    return (
      !this.state.animating &&
      (this.state.gameState === GameState.PlayerCardsDealt || this.state.gameState === GameState.FlopShown || this.state.gameState === GameState.RiverShown)
    );
  }

  private resizeHome = () => {
    const homeWrapper = document.querySelector<HTMLDivElement>("div.home-wrapper");
    homeWrapper.removeAttribute("style");
    this.showHome();
  };

  private showHome = () => {
    const homeWrapper = document.querySelector<HTMLDivElement>("div.home-wrapper");
    const cs = window.getComputedStyle(homeWrapper);
    let scale = 1;

    if (homeWrapper.offsetWidth > document.body.offsetWidth || homeWrapper.offsetHeight > document.body.offsetHeight) {
      const scaleX = document.body.offsetWidth / homeWrapper.offsetWidth;
      const scaleY = document.body.offsetHeight / homeWrapper.offsetHeight;
      scale = Math.min(scaleX, scaleY);
      homeWrapper.style.transform = `scale(${scale})`;
    }

    (window as any).homeScale = scale;
    const pt = parseFloat(cs.paddingTop);
    const pb = parseFloat(cs.paddingBottom);
    const delta = (document.body.offsetHeight - homeWrapper.offsetHeight * scale) / 2 / scale;
    homeWrapper.style.paddingTop = `${pt + delta}px`;
    homeWrapper.style.paddingBottom = `${pb + delta}px`;
    homeWrapper.style.width = `${document.body.offsetWidth / scale}px`;

    this.setState({ showing: true }, () => {
      window.setTimeout(() => window.dispatchEvent(new Event("home-showing")), 0);
    });
  };

  render() {
    if (!this.state.showing) {
      window.setTimeout(this.showHome, 0);
    }

    return (
      <div className={`home-wrapper ${this.state.showing ? "show" : ""}`}>
        <Logo />
        <Card {...this.state.deckCards[0]} faceUp={false} winningCards={[]} losingCards={[]} classNameExtra="bottom-deck" />
        <div>
          <CardBlock
            cards={this.state.dealerCards}
            faceUp={this.state.gameState >= GameState.DealerShown}
            winningCards={this.state.resolution?.winningCards}
            losingCards={this.state.resolution?.losingCards}
            blockName="dealer-cards"
            label="Dealer"
          />
        </div>
        <HandDescription ph={this.ph} pokerRank={this.state.dealerPokerRank} showHandDescription={true} />
        <div>
          <CardBlock
            cards={this.state.riverCards?.slice(1, 2)}
            faceUp={this.state.gameState >= GameState.RiverShown}
            winningCards={this.state.resolution?.winningCards}
            losingCards={this.state.resolution?.losingCards}
            blockName="river-cards"
            label="River"
          />
          <CardBlock
            cards={this.state.riverCards?.slice(0, 1)}
            faceUp={this.state.gameState >= GameState.RiverShown}
            winningCards={this.state.resolution?.winningCards}
            losingCards={this.state.resolution?.losingCards}
            blockName="turn-cards"
            label="Turn"
          />
          <CardBlock
            cards={this.state.flopCards}
            faceUp={this.state.gameState >= GameState.FlopShown}
            winningCards={this.state.resolution?.winningCards}
            losingCards={this.state.resolution?.losingCards}
            blockName="flop-cards"
            label="The Flop"
          />
        </div>
        <ResolutionDescription resolution={this.state.resolution} />
        <BetBlock
          dealerNotQualified={this.state.resolution ? !this.state.resolution.dealerQualified : false}
          bets={this.state.bets}
          payouts={this.state.payouts}
          clearBet={this.clearBet}
          tripsPayMultiples={this.pm.tripsPayMultiples}
          blindPayMultiples={this.pm.blindPayMultiples}
          showPayoutTables={this.settings.showPayoutTables}
        />
        <CardBlock
          cards={this.state.playerCards}
          faceUp={this.state.gameState >= GameState.PlayerCardsDealt}
          winningCards={this.state.resolution?.winningCards}
          losingCards={this.state.resolution?.losingCards}
          folded={this.state.folded}
          blockName="player-cards"
        />
        <HandDescription
          ph={this.ph}
          pokerRank={this.state.playerPokerRank}
          showHandDescription={this.state.gameState === GameState.DealerShown || this.settings.showHandDescription}
        />
        <ButtonBlock
          gameState={this.state.gameState}
          advanceGameState={this.advanceGameState}
          newHand={this.newHand}
          raiseTrips={this.raiseTrips}
          raiseAnteAndBlind={this.raiseAnteAndBlind}
          betPlay={this.betPlay}
          noDeal={this.state.bets.ante === 0}
          fold={this.fold}
          animating={this.state.animating}
          defaultButton={this.computeDefaultButton()}
        />
        <ChipsStack bank={this.bank} onTable={this.state.payouts ? this.state.bets.total + this.state.payouts.total : 0} />
        {this.state.resolution && <Connector winner={this.state.resolution.winner} playerFolded={this.state.resolution.playerFolded} />}
        {this.settings.showStrategy && <StrategyBlock gameState={this.state.gameState} show={this.offerStrategy} />}
        <Menu settings={this.settings} />
      </div>
    );
  }
}
