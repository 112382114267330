interface UserChips {
  count: number;
  most: number;
  least: number;
}

export class Banker {
  private userChips: UserChips;

  constructor() {
    const userChipsJson = window.localStorage.getItem("userChipsJson");
    if (userChipsJson) {
      this.userChips = JSON.parse(userChipsJson);
    } else {
      this.userChips = { count: 1000, most: 1000, least: 1000 };
    }
  }

  get balance(): number {
    return this.userChips.count;
  }

  payOut(n: number): number {
    this.userChips.count -= n;
    this.userChips.least = Math.min(this.userChips.least, this.userChips.count);
    return n;
  }

  payIn(n: number): number {
    this.userChips.count += n;
    this.userChips.most = Math.max(this.userChips.most, this.userChips.count);
    return n;
  }

  reset(): number {
    this.userChips = { count: 1000, most: 1000, least: 1000 };
    this.saveToStorage();
    return this.balance;
  }

  saveToStorage(): void {
    window.localStorage.setItem("userChipsJson", JSON.stringify(this.userChips));
  }
}
