import * as React from "react";
import { GameState } from "./Home";
import { Layer } from "./Layer";
import "./StrategyBlock.css";

export interface StrategyBlockProps {
  gameState: GameState;
  show: boolean;
}

class StrategyBlockState {
  open: boolean = false;
  openedState: GameState;
}

export class StrategyBlock extends React.Component<StrategyBlockProps, StrategyBlockState> {
  constructor(props: StrategyBlockProps) {
    super(props);
    this.state = new StrategyBlockState();
  }

  render() {
    const blockClass = !this.state.open ? "closed" : this.state.openedState === this.props.gameState ? "open" : "closed";

    if (this.state.open && this.state.openedState !== this.props.gameState) {
      window.setTimeout(() => this.setState({ open: false }), 0);
    }

    return (
      <Layer
        layerName="strategy"
        style={this.state.open ? { pointerEvents: "auto" } : null}
        onClick={this.state.open ? () => this.setState({ open: false }) : null}
      >
        {this.props.show && (
          <div className={`strategy-block ${blockClass}`} onClick={() => this.setState({ open: !this.state.open, openedState: this.props.gameState })}>
            <div className="header">Strategy</div>
            {this.props.gameState === GameState.PlayerCardsDealt && (
              <div className="body">
                <p>Bet 4X if holding:</p>
                <ul>
                  <li>Pair better than deuces</li>
                  <li>Ace-high plus anything</li>
                  <li>King-high plus 5 or better (anything if suited)</li>
                  <li>Queen-high plus 8 or better (5 or better if suited)</li>
                  <li>Jack-high plus 10 (8 or better if suited)</li>
                </ul>
              </div>
            )}
            {this.props.gameState === GameState.FlopShown && (
              <div className="body">
                <p>Bet 2X if holding:</p>
                <ul>
                  <li>Two pair or better with a hidden member</li>
                  <li>Hidden pair except pocket deuces</li>
                  <li>Four to a flush with hidden 10 or better to that flush</li>
                </ul>
              </div>
            )}
            {this.props.gameState === GameState.RiverShown && (
              <div className="body">
                <p>Bet 1X if holding:</p>
                <ul>
                  <li>Hidden pair or better</li>
                  <li>Fewer than 21 cards the dealer can beat you with</li>
                </ul>
              </div>
            )}
            <div className="footer">
              Strategy provided by{" "}
              <a href="https://wizardofodds.com/games/ultimate-texas-hold-em/" target="_blank" rel="noopener noreferrer">
                Wizard of Odds
              </a>
            </div>
          </div>
        )}
      </Layer>
    );
  }
}
