interface ISettings {
  showPayoutTables: boolean;
  showSuggestions: boolean;
  showStrategy: boolean;
  showHandDescription: boolean;

  [key: string]: boolean;
}

export class Settings {
  private settings: ISettings;

  constructor(private onUpdated: () => void) {
    this.settings = this.defaultSettings;
    const savedJson = window.localStorage.getItem("settings");
    if (savedJson) {
      const savedSettings = JSON.parse(savedJson) as ISettings;
      Object.keys(savedSettings).forEach(k => (this.settings[k] = savedSettings[k]));
    }
  }

  private defaultSettings: ISettings = {
    showPayoutTables: true,
    showSuggestions: true,
    showStrategy: true,
    showHandDescription: true
  };

  get showPayoutTables(): boolean {
    return this.settings.showPayoutTables;
  }

  set showPayoutTables(value: boolean) {
    this.settings.showPayoutTables = value;
    this.onUpdated();
  }

  get showSuggestions(): boolean {
    return this.settings.showSuggestions;
  }

  set showSuggestions(value: boolean) {
    this.settings.showSuggestions = value;
    this.onUpdated();
  }

  get showStrategy(): boolean {
    return this.settings.showStrategy;
  }

  set showStrategy(value: boolean) {
    this.settings.showStrategy = value;
    this.onUpdated();
  }

  get showHandDescription(): boolean {
    return this.settings.showHandDescription;
  }

  set showHandDescription(value: boolean) {
    this.settings.showHandDescription = value;
    this.onUpdated();
  }

  save() {
    window.localStorage.setItem("settings", JSON.stringify(this.settings));
  }
}
