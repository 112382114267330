import { HandRank, HandResolution, HandWinner } from "../Cards/PokerHand";
import { Bets } from "./Home";

export type PayMultiples = { hr: HandRank; m: number }[];

export class PayOuts {
  ante: number = 0;
  blind: number = 0;
  play: number = 0;
  trips: number = 0;

  get total(): number {
    return Math.max(0, this.ante) + Math.max(0, this.blind) + Math.max(0, this.play) + Math.max(0, this.trips);
  }
}

export class PayMaster {
  private outcomeByWinner: {
    [winner: string /* HandWinner */]: { [qualifies: string /* boolean */]: { blind: HandWinner; ante: HandWinner; play: HandWinner } };
  } = {};

  private blindMultipleByHandRank: { [pokerRank: string /* HandRank  */]: number } = {};
  private tripsMultipleByHandRank: { [pokerRank: string /* HandRank  */]: number } = {};

  constructor() {
    //    populate outcomes
    this.outcomeByWinner[HandWinner.Player] = {};
    this.outcomeByWinner[HandWinner.Player][`${true}`] = { blind: HandWinner.Player, ante: HandWinner.Player, play: HandWinner.Player };
    this.outcomeByWinner[HandWinner.Player][`${false}`] = { blind: HandWinner.Player, ante: HandWinner.Push, play: HandWinner.Player };
    this.outcomeByWinner[HandWinner.Dealer] = {};
    this.outcomeByWinner[HandWinner.Dealer][`${true}`] = { blind: HandWinner.Dealer, ante: HandWinner.Dealer, play: HandWinner.Dealer };
    this.outcomeByWinner[HandWinner.Dealer][`${false}`] = { blind: HandWinner.Dealer, ante: HandWinner.Push, play: HandWinner.Dealer };
    this.outcomeByWinner[HandWinner.Push] = {};
    this.outcomeByWinner[HandWinner.Push][`${true}`] = { blind: HandWinner.Push, ante: HandWinner.Push, play: HandWinner.Push };
    this.outcomeByWinner[HandWinner.Push][`${false}`] = { blind: HandWinner.Push, ante: HandWinner.Push, play: HandWinner.Push };

    // populate blind multiples (from https://wizardofodds.com/games/ultimate-texas-hold-em/)
    this.blindMultipleByHandRank[HandRank.RoyalFlush] = 500;
    this.blindMultipleByHandRank[HandRank.StraightFlush] = 50;
    this.blindMultipleByHandRank[HandRank.Four_of_a_Kind] = 10;
    this.blindMultipleByHandRank[HandRank.FullHouse] = 3;
    this.blindMultipleByHandRank[HandRank.Flush] = 3 / 2;
    this.blindMultipleByHandRank[HandRank.Straight] = 1;

    //  populate trips multiples (from table 2 at https://wizardofodds.com/games/ultimate-texas-hold-em/)
    this.tripsMultipleByHandRank[HandRank.RoyalFlush] = 50;
    this.tripsMultipleByHandRank[HandRank.StraightFlush] = 40;
    this.tripsMultipleByHandRank[HandRank.Four_of_a_Kind] = 30;
    this.tripsMultipleByHandRank[HandRank.FullHouse] = 8;
    this.tripsMultipleByHandRank[HandRank.Flush] = 6;
    this.tripsMultipleByHandRank[HandRank.Straight] = 5;
    this.tripsMultipleByHandRank[HandRank.Three_of_a_Kind] = 3;
  }

  private outcome(winner: HandWinner, dealerQualifies: boolean): { blind: HandWinner; ante: HandWinner; play: HandWinner } {
    return this.outcomeByWinner[winner][`${dealerQualifies}`];
  }

  private blindMultiple(handRank: HandRank) {
    return this.blindMultipleByHandRank[handRank] || 0;
  }

  private tripsMultiple(handRank: HandRank) {
    return this.tripsMultipleByHandRank[handRank] || 0;
  }

  get tripsPayMultiples(): PayMultiples {
    return Object.keys(this.tripsMultipleByHandRank)
      .map(hrs => ({ hr: parseInt(hrs), m: this.tripsMultipleByHandRank[hrs] }))
      .sort((a, b) => b.m - a.m);
  }

  get blindPayMultiples(): PayMultiples {
    return Object.keys(this.blindMultipleByHandRank)
      .map(hrs => ({ hr: parseInt(hrs), m: this.blindMultipleByHandRank[hrs] }))
      .sort((a, b) => b.m - a.m);
  }

  computePayouts(bets: Bets, hr: HandResolution): PayOuts {
    const payOuts = new PayOuts();
    const playerHand = hr.playerBest.handRank;

    if (hr.playerFolded) {
      payOuts.ante = -1;
      payOuts.blind = -1;
      payOuts.play = -1;
      payOuts.trips = playerHand >= HandRank.Three_of_a_Kind ? bets.trips * this.tripsMultiple(playerHand) : -1;
    } else {
      const outcome = this.outcome(hr.winner, hr.dealerQualified);
      payOuts.ante = outcome.ante === HandWinner.Player ? bets.ante : outcome.ante === HandWinner.Push ? 0 : -1;
      payOuts.blind = outcome.blind === HandWinner.Player ? bets.blind * this.blindMultiple(playerHand) : outcome.blind === HandWinner.Push ? 0 : -1;
      payOuts.play = outcome.play === HandWinner.Player ? bets.play : outcome.play === HandWinner.Push ? 0 : -1;
      payOuts.trips = playerHand >= HandRank.Three_of_a_Kind ? bets.trips * this.tripsMultiple(playerHand) : -1;
    }

    return payOuts;
  }
}
