import * as React from "react";
import "./Toggle.css";

export interface ToggleProps {
  labelText: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

class ToggleState {}

export class Toggle extends React.Component<ToggleProps, ToggleState> {
  constructor(props: ToggleProps) {
    super(props);
    this.state = new ToggleState();
  }

  render() {
    const id = this.props.labelText.toLowerCase().replace(/\s+/g, "-");

    return (
      <div className="can-toggle">
        <input id={id} type="checkbox" checked={this.props.value} onChange={evt => this.props.onChange(evt.target.checked)} />
        <label htmlFor={id}>
          <div className="can-toggle__label-text">{this.props.labelText}</div>
          <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
        </label>
      </div>
    );
  }
}
