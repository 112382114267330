import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Home } from "./Home/Home";

function App() {
  function getBodySizeCss(): string {
    return `body { width: ${window.innerWidth}px; height: ${window.innerHeight}px; }`;
  }

  window.addEventListener("resize", () => {
    const bodySizeStyle = document.getElementById("body-size-style");
    if (bodySizeStyle) {
      bodySizeStyle.innerHTML = getBodySizeCss();
    }
  });

  return (
    <div className="App">
      <style id="body-size-style" dangerouslySetInnerHTML={{ __html: getBodySizeCss() }} />
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      <Home />
    </div>
  );
}

export default App;
