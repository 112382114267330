import * as React from "react";
import "./Bet.css";

export enum StackOrTaken {
  stack = "stack",
  taken = "taken"
}

export enum BetOrPay {
  bet = "bet",
  pay = "pay"
}

export interface BetProps {
  value: number;
  betClick?: () => void;
  stackOrTaken: StackOrTaken;
  betOrPay: BetOrPay;
  betName: "trips" | "ante" | "blind" | "play";
}

class BetState {}

export class Bet extends React.Component<BetProps, BetState> {
  private valueDisplay: JSX.Element;

  constructor(props: BetProps) {
    super(props);
    this.state = new BetState();
  }

  private setValueDisplay(): void {
    const value = this.props.value;

    if (value > 0) {
      if (value % 1 === 0.5) {
        this.valueDisplay = (
          <>
            {Math.floor(value)}
            <span className="half">½</span>
          </>
        );
      } else {
        this.valueDisplay = <>{value}</>;
      }
    }
  }

  render() {
    const hasValue = this.props.value > 0;

    this.setValueDisplay();

    return (
      <div
        className={`bet ${this.props.betOrPay}-${this.props.betName} ${hasValue ? "table" : this.props.stackOrTaken}`}
        onClick={(hasValue && this.props.betClick) || null}
        title={(hasValue && this.props.betClick && "Click to clear bet") || null}
      >
        {this.valueDisplay}
      </div>
    );
  }
}
