import * as React from "react";
import logo from "./Ultimate-Texas-Holdem-logo.png";

export class Logo extends React.Component<{}, {}> {
  render() {
    return (
      <div className="logo-wrapper">
        <img alt="Ultimate Texas Hold ’Em" src={logo} />
      </div>
    );
  }
}
