import { Rank, Suit, SuitRank } from "./Card";

export class Deck {
  private cards: SuitRank[] = [];

  constructor() {
    for (let s = Suit.Hearts; s <= Suit.Diamonds; ++s) {
      for (let r = Rank.Two; r <= Rank.Ace; ++r) {
        this.cards.push({ suit: s, rank: r, suitRankString: `${Rank[r]}_${Suit[s]}` });
      }
    }
  }

  private getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // the maximum is exclusive and the minimum is inclusive
  }

  /*
  private shuffleOnce(): void {
    const shuffled: SuitRank[] = [];

    while (this.cards.length > 0) {
      const index = this.getRandomInt(0, this.cards.length);
      shuffled.push(this.cards.splice(index, 1)[0]);
    }

    this.cards = shuffled;
  }

    shuffle(): void {
    for (let i = 0; i < 10; ++i) {
      this.shuffleOnce();
    }
  }
 */

  // private static randoms = new Uint16Array(500);
  // private static iRandom: number = null;
  // private static twoTo16th = Math.pow(2, 16);

  //  replacement for Math.random() using crypto.getRandomValues
  //  is it any better? dunno.
  private static get nextRandom(): number {
    return Math.random();

    // if (!Deck.iRandom || Deck.iRandom === Deck.randoms.length) {
    //   window.crypto.getRandomValues(Deck.randoms);
    //   Deck.iRandom = 0;
    // }

    // const next = Deck.randoms[Deck.iRandom++];
    // return next / Deck.twoTo16th;
  }

  shuffle(): void {
    //  an implementation of the modern version of the Fisher–Yates shuffle (http://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm)
    //  time to do 10,000 shuffles of a 53-card deck ~= 615 ms
    //  verified with 1,000,000 shuffles (7,000,000 hands) to deliver results closely in-line with expected distribution
    for (let i = this.cards.length - 1; i >= 1; --i) {
      const j = Math.floor(Deck.nextRandom * (i + 1));
      if (j !== i) {
        var temp = this.cards[i];
        this.cards[i] = this.cards[j];
        this.cards[j] = temp;
      }
    }
  }

  take(n: number): SuitRank[] {
    return this.cards.splice(0, n);
  }

  burnOne(): void {
    this.take(1);
  }

  cardsWithout(knownCards: SuitRank[]): SuitRank[] {
    return this.cards.filter(dc => !knownCards.some(c => c.suitRankString === dc.suitRankString));
  }
}
