import * as React from "react";
import { HandResolution, HandWinner } from "../Cards/PokerHand";

export interface ResolutionDescriptionProps {
  resolution: HandResolution;
}

export class ResolutionDescription extends React.Component<ResolutionDescriptionProps, {}> {
  private get resolutionHTML(): JSX.Element {
    const hr = this.props.resolution;
    if (!hr) return <>&nbsp;</>;

    let whoWon: string;
    if (hr.playerFolded) {
      if (hr.winner === HandWinner.Dealer) {
        whoWon = "Good fold!";
      } else {
        whoWon = "You folded.";
      }
    } else {
      if (hr.winner === HandWinner.Dealer) {
        whoWon = "Sorry.";
      } else if (hr.winner === HandWinner.Player) {
        whoWon = "Winner!";
      }
    }

    //  replace all regulars spaces with non-breaking and then replace the non-breaking before "Beats" with a regular space
    const msg = hr.message.replace(/\s+/g, " ").replace(" Beats", " Beats");

    if (hr.winner === HandWinner.Push && !whoWon) {
      return <span className="who-won">{msg}</span>;
    }

    return (
      <>
        {whoWon && <span className="who-won">{whoWon}</span>}
        <span>{msg}</span>
      </>
    );
  }

  render() {
    return (
      <div className={`resolution-description ${!this.props.resolution ? "hidden" : ""}`}>
        <div>{this.resolutionHTML}</div>
      </div>
    );
  }
}
