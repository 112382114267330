import * as React from "react";
import { Layer } from "../Home/Layer";
import { HandWinner } from "./PokerHand";

export interface ConnectorProps {
  winner: HandWinner;
  playerFolded: boolean;
}

class ConnectorState {
  aBeat: boolean = false;
}

export class Connector extends React.Component<ConnectorProps, ConnectorState> {
  constructor(props: ConnectorProps) {
    super(props);
    this.state = new ConnectorState();
  }

  private markTheCards = (winningOrLosing: string, color: string, locationY: number): JSX.Element => {
    const cards = document.querySelectorAll<HTMLDivElement>(`.card.${winningOrLosing}`);
    const boxes: DOMRect[] = [];
    cards.forEach(c => boxes.push(c.getBoundingClientRect()));

    boxes.sort((a, b) => {
      let dif = Math.round(b.top) - Math.round(a.top);
      if (dif === 0) dif = Math.round(b.left) - Math.round(a.left);
      return dif;
    });

    if (boxes.length > 1) {
      const centerStrings: string[] = boxes.map(b => `${Math.round(b.left + b.width / 2)},${Math.round(b.top + b.height * locationY)}`);
      return (
        <>
          <polyline
            points={centerStrings.join(" ")}
            fill="none"
            stroke="rgba(255, 255, 255, 0.8)"
            strokeWidth={5}
            markerStart="url(#whitedot)"
            markerMid="url(#whitedot)"
            markerEnd="url(#whitedot)"
          />
          <polyline
            points={centerStrings.join(" ")}
            fill="none"
            stroke={color}
            strokeWidth={3}
            markerStart="url(#dot)"
            markerMid="url(#dot)"
            markerEnd="url(#dot)"
          />
        </>
      );
    }

    return null;
  };

  render(): JSX.Element {
    if (!this.state.aBeat) {
      window.setTimeout(() => this.setState({ aBeat: true }), 500);
      return null;
    }

    const appBox = document.querySelector("div.App").getBoundingClientRect();

    return (
      <Layer layerName="card-connector">
        <svg viewBox={`${appBox.left} ${appBox.top} ${appBox.width} ${appBox.height}`} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <marker id="dot" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5">
              <circle cx="5" cy="5" r="5" fill="#333" />
            </marker>
            <marker id="whitedot" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="5" markerHeight="5">
              <circle cx="5" cy="5" r="3.5" fill="rgba(255, 255, 255, 0.8)" />
            </marker>
          </defs>
          {this.markTheCards("winning", "#333", 1 / 2 /* this.props.winner === HandWinner.Dealer ? 1 / 3 : 2 / 3 */)}
        </svg>
      </Layer>
    );
  }
}
