import * as React from "react";
import { PokerHand, PokerRank } from "../Cards/PokerHand";

export interface HandDescriptionProps {
  ph: PokerHand;
  pokerRank: PokerRank[];
  showHandDescription: boolean;
}

class HandDescriptionState {
  msg: string = "";
}

export class HandDescription extends React.Component<HandDescriptionProps, HandDescriptionState> {
  constructor(props: HandDescriptionProps) {
    super(props);
    this.state = new HandDescriptionState();
  }

  render() {
    let displayMsg: string = null;

    if (this.props.showHandDescription) {
      const msg = this.props.pokerRank && this.props.ph.pokerRankArrayAsString(this.props.pokerRank);
      displayMsg = this.state.msg;

      if (msg !== this.state.msg) {
        window.setTimeout(() => this.setState({ msg: msg }), msg ? 600 : 0);
        displayMsg = null;
      }
    }

    return (
      <div className="hand-description">
        <div>{displayMsg || <>&nbsp;</>}</div>
      </div>
    );
  }
}
