import * as React from "react";
import { Layer } from "./Layer";
import "./Menu.css";
import { Settings } from "./Settings";
import { Toggle } from "./Toggle";

export interface MenuProps {
  settings: Settings;
}

class MenuState {
  open: boolean = false;
}

export class Menu extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = new MenuState();
  }

  private toggleMenuOpen = () => {
    this.setState({ open: !this.state.open }, () => {
      if (!this.state.open) {
        this.props.settings.save();
      }
    });
  };

  private onLayerClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.open && evt.target === evt.currentTarget) {
      this.toggleMenuOpen();
    }
  };

  render() {
    return (
      <Layer layerName="menu-layer" style={this.state.open ? { pointerEvents: "auto" } : null} onClick={this.state.open ? this.onLayerClick : null}>
        <div className={`menu ${this.state.open ? "open" : ""}`}>
          <div className="menu-top-row" onClick={this.toggleMenuOpen}>
            <button type="button" className="menu-button">
              ⋮
            </button>
            <span className="title">
              Options
              <span className="close-x">⨉</span>
            </span>
          </div>
          {this.state.open && (
            <div className="menu-items">
              <Toggle labelText="Suggestions" value={this.props.settings.showSuggestions} onChange={v => (this.props.settings.showSuggestions = v)} />
              <Toggle labelText="Strategy Card" value={this.props.settings.showStrategy} onChange={v => (this.props.settings.showStrategy = v)} />
              <Toggle
                labelText="Hand Description"
                value={this.props.settings.showHandDescription}
                onChange={v => (this.props.settings.showHandDescription = v)}
              />
              <Toggle labelText="Payout Tables" value={this.props.settings.showPayoutTables} onChange={v => (this.props.settings.showPayoutTables = v)} />
              <div className="credits">
                <b>Ultimate Texas Hold ’Em</b> is a registered trademark of Bally Gaming, Inc.
              </div>
            </div>
          )}
        </div>
      </Layer>
    );
  }
}
