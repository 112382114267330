import { Rank, Suit, SuitRank } from "./Card";
import { Deck } from "./Deck";

export enum HandWinner {
  Dealer,
  Player,
  Push
}

export enum HandRank {
  HighCard,
  Pair,
  TwoPair,
  Three_of_a_Kind,
  Straight,
  Flush,
  FullHouse,
  Four_of_a_Kind,
  StraightFlush,
  RoyalFlush
}

export interface HandResolution {
  dealerQualified: boolean;
  message: string;
  playerBest: PokerRank;
  winner: HandWinner;
  playerFolded: boolean;
  decider: PokerRank;
  winningCards: SuitRank[];
  losingCards: SuitRank[];
}

export interface PokerRank {
  handRank?: HandRank;
  cardRank: Rank;
  theCards: SuitRank[];
  hiddenMember?: boolean;
}

export class PokerHand {
  pokerRankArrayAsString(pra: PokerRank[]): string {
    if (pra.length === 2 && pra[0].handRank === HandRank.HighCard && pra[0].theCards[0].suit === pra[1].theCards[0].suit) {
      return `Suited ${this.rankName(pra[0])}, ${this.rankName(pra[1])}`;
    }

    let nCards = 0;
    let s = "";
    for (let i = 0; i < pra.length && nCards < 5; i += this.pokerRanksConsumed(pra[i])) {
      if (s) s = s += ", ";

      s += this.displayPokerRank(pra, i);
      nCards += this.cardsConsumed(pra[i]);
    }

    return s;
  }

  static handRankString(hr: HandRank | string): string {
    const s = typeof hr === "string" ? hr : HandRank[hr];
    return s.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/_/g, " ");
  }

  pokerRankString(pr: PokerRank): string {
    if (pr.handRank === HandRank.HighCard) {
      return `${this.rankName(pr)}-high`;
    }

    return PokerHand.handRankString(pr.handRank);
  }

  private rankName(pr: PokerRank): string {
    return pr.cardRank < Rank.Jack ? pr.cardRank.toString() : Rank[pr.cardRank];
  }

  private rankNamePlural(pr: PokerRank): string {
    return `${this.rankName(pr)}s`;
  }

  private pokerRanksConsumed(pr: PokerRank): number {
    switch (pr.handRank) {
      case HandRank.HighCard:
      case HandRank.Pair:
      case HandRank.Three_of_a_Kind:
      case HandRank.Four_of_a_Kind:
        return 1;
      case HandRank.TwoPair:
      case HandRank.FullHouse:
        return 2;
      case HandRank.Straight:
      case HandRank.StraightFlush:
        return 1;
      case HandRank.Flush:
      case HandRank.RoyalFlush:
        return 5;
    }

    return 1;
  }

  private cardsConsumed(pr: PokerRank): number {
    switch (pr.handRank) {
      case HandRank.HighCard:
        return 1;
      case HandRank.Pair:
        return 2;
      case HandRank.Three_of_a_Kind:
        return 3;
      case HandRank.TwoPair:
      case HandRank.Four_of_a_Kind:
        return 4;
      case HandRank.FullHouse:
      case HandRank.Straight:
      case HandRank.StraightFlush:
      case HandRank.Flush:
      case HandRank.RoyalFlush:
        return 5;
    }

    return 1;
  }

  static getSuitSymbol(sr: SuitRank): string {
    switch (sr.suit) {
      case Suit.Hearts:
        return "♥";
      case Suit.Spades:
        return "♠";
      case Suit.Clubs:
        return "♣";
      case Suit.Diamonds:
        return "♦";
    }
  }

  static getRankSymbol(sr: SuitRank): string {
    switch (sr.rank) {
      case Rank.Ace:
        return "A";
      case Rank.King:
        return "K";
      case Rank.Queen:
        return "Q";
      case Rank.Jack:
        return "J";
      default:
        return sr.rank.toString();
    }
  }

  private suitRankString(sr: SuitRank): string {
    return `${PokerHand.getRankSymbol(sr)}${PokerHand.getSuitSymbol(sr)}`;
  }

  private card0SuitRankString(pr: PokerRank) {
    return this.suitRankString(pr.theCards[0]);
  }

  //  this assumes that the PokerRank array is ordered with the highest stuff first
  private displayPokerRank(pra: PokerRank[], i: number): string {
    const rankName = this.rankName(pra[i]);
    const rankNamePlural = this.rankNamePlural(pra[i]);
    const pokerRankString = this.pokerRankString(pra[i]);

    switch (pra[i].handRank) {
      case HandRank.HighCard:
        return i === 0 ? `${rankName}-high` : rankName;
      case HandRank.Pair:
        return `Pair of ${rankNamePlural}`;
      case HandRank.TwoPair:
      case HandRank.FullHouse:
        let rank1 = pra[0];
        let rank2 = pra[1];
        if (rank2.cardRank > rank1.cardRank) {
          rank1 = rank2;
          rank2 = pra[0];
        }
        return `${pokerRankString} (${this.rankNamePlural(rank1)} and ${this.rankNamePlural(rank2)})`;
      case HandRank.Three_of_a_Kind:
        return `Three ${rankNamePlural}`;
      case HandRank.Four_of_a_Kind:
        return `Four ${rankNamePlural}`;
      case HandRank.Straight:
      case HandRank.StraightFlush:
        return `${rankName}-high ${pokerRankString}`;
      case HandRank.Flush:
        return `${pokerRankString} (${pra
          .slice(0, 5)
          .map(pr => this.card0SuitRankString(pr))
          .join(", ")})`;
      case HandRank.RoyalFlush:
        return pokerRankString;
    }

    return pokerRankString;
  }

  private compareCardObjectsHighToLow(a: SuitRank, b: SuitRank) {
    return a.rank === b.rank ? b.suit - a.suit : b.rank - a.rank;
  }

  rankHand(hiddenCards: SuitRank[], flopCards: SuitRank[] = [], riverCards: SuitRank[] = []): PokerRank[] {
    const cardsToCheck = hiddenCards.concat(flopCards || []).concat(riverCards || []);
    cardsToCheck.sort(this.compareCardObjectsHighToLow);

    if (cardsToCheck.length === 2) {
      return isPair(cardsToCheck) || mustBeHighCard(cardsToCheck);
    }

    function isMemberHidden(cards: SuitRank[]): boolean {
      return cards.some(c => !!hiddenCards.find(hc => hc.rank === c.rank && hc.suit === c.suit));
    }

    function n_of_a_Kind(cards: SuitRank[], n: number, arrayOfValuesToIgnore: Rank[]): PokerRank[] {
      const inspectThrough = cards.length - n;
      for (let i = 0; i <= inspectThrough; ++i) {
        const matchNumericRank = cards[i].rank;

        if (arrayOfValuesToIgnore.indexOf(matchNumericRank) !== -1) continue;

        let foundNInARow = true;
        for (let j = 1; foundNInARow && j < n; ++j) {
          foundNInARow = cards[i + j].rank === matchNumericRank;
        }

        if (foundNInARow) {
          const theCards = cards.slice(i, i + n);
          return [{ cardRank: matchNumericRank, theCards: theCards, hiddenMember: isMemberHidden(theCards) }];
        }
      }

      return null;
    }

    function appendRemainingCards(addTo: PokerRank[], cards: SuitRank[]): PokerRank[] {
      let cardsWeHave: SuitRank[] = [];

      //  collect the cards we've already accounted for
      addTo.forEach(pr => (cardsWeHave = cardsWeHave.concat(pr.theCards)));

      //  add each card we don't have to our array
      cards.forEach(c => {
        if (!cardsWeHave.find(c2 => c2.rank === c.rank && c2.suit === c.suit)) {
          addTo.push({ handRank: HandRank.HighCard, cardRank: c.rank, theCards: [c] });
        }
      });

      return addTo;
    }

    function isRoyalFlush(cards: SuitRank[]): PokerRank[] {
      const straightFlush = isStraightFlush(cards);
      if (straightFlush && straightFlush[0].cardRank === Rank.Ace) {
        straightFlush[0].handRank = HandRank.RoyalFlush;
        return straightFlush;
      }
      return null;
    }

    function isStraightFlush(cards: SuitRank[]): PokerRank[] {
      const straight = isStraight(cards, 5, true);
      if (straight) {
        straight[0].handRank = HandRank.StraightFlush;
        return straight;
      }
      return null;
    }

    function isFourOfAKind(cards: SuitRank[]): PokerRank[] {
      const fourOfAKind = n_of_a_Kind(cards, 4, []);
      if (fourOfAKind) {
        fourOfAKind[0].handRank = HandRank.Four_of_a_Kind;
        return appendRemainingCards(fourOfAKind, cards);
      }

      return null;
    }

    function isFullHouse(cards: SuitRank[]): PokerRank[] {
      const three = n_of_a_Kind(cards, 3, []);
      if (three) {
        const two = n_of_a_Kind(cards, 2, [three[0].cardRank]);
        if (two) {
          three[0].handRank = HandRank.FullHouse;
          two[0].handRank = HandRank.FullHouse;
          three[0].hiddenMember = three[0].hiddenMember || two[0].hiddenMember;
          return appendRemainingCards(three.concat(two), cards);
        }
      }

      return null;
    }

    function isFlush(cards: SuitRank[]): PokerRank[] {
      const flushSize = 5;

      const suitCards: { [suitString: string]: SuitRank[] } = {};
      cards.forEach(function(c) {
        if (suitCards[c.suit]) suitCards[c.suit].push(c);
        else suitCards[c.suit] = [c];
      });

      for (let suit in suitCards) {
        if (suitCards[suit].length >= flushSize) {
          const cardsInFlush = suitCards[suit].slice(0, flushSize);

          //  return a PokerRank entry for each card in the flush
          const hiddenMember = isMemberHidden(cardsInFlush);
          const flush = cardsInFlush.map(c => ({ handRank: HandRank.Flush, cardRank: c.rank, theCards: [c], hiddenMember: hiddenMember }));
          return appendRemainingCards(flush, cards);
        }
      }

      return null;
    }

    function cardOfSuit(arrayOfCards: SuitRank[], theSuit: Suit): SuitRank {
      if (arrayOfCards) {
        for (let i = 0; i < arrayOfCards.length; ++i) {
          if (arrayOfCards[i].suit === theSuit) return arrayOfCards[i];
        }
      }

      return null;
    }

    function isStraight(cards: SuitRank[], straightSize: number, checkForStraightFlush: boolean) {
      let theCards,
        retVal = null;

      //  finding a 5-card straight in a 7-card hand is difficult. the array we're passed is sorted high-to-low but that aggravates things when there's a pair or two in the set.

      //  create arrays of cards organized by their value
      //  create entries for both high and low ace to make sure we deal with that
      const numericRanksPresent: { [rankString: string]: SuitRank[] } = {};
      for (let i = 0; i < cards.length; ++i) {
        const c = cards[i];

        if (!numericRanksPresent[c.rank]) {
          numericRanksPresent[c.rank] = [];

          if (c.rank === Rank.Ace) {
            numericRanksPresent[1] = [];
          }
        }

        numericRanksPresent[c.rank].push(c);

        if (c.rank === Rank.Ace) {
          numericRanksPresent[1].push(c);
        }
      }

      //  we can't have a straight unless we have enough unique ranks
      if (Object.keys(numericRanksPresent).length < straightSize) return null;

      for (let highCardRank = Rank.Ace; retVal === null && highCardRank >= straightSize; --highCardRank) {
        if (!numericRanksPresent[highCardRank]) continue;

        //  if we've found a card rank, we walk through its suits if we're looking for a flush. if not, we just do one
        for (let ci = 0; retVal === null && ci < (checkForStraightFlush ? numericRanksPresent[highCardRank].length : 1); ++ci) {
          const highCardSuit = numericRanksPresent[highCardRank][ci].suit;

          let haveStraight = true;
          for (let i = 1; haveStraight && i < straightSize; ++i) {
            haveStraight =
              Array.isArray(numericRanksPresent[highCardRank - i]) &&
              (checkForStraightFlush ? cardOfSuit(numericRanksPresent[highCardRank - i], highCardSuit) !== null : true);
          }

          if (haveStraight) {
            theCards = [];
            for (let i = 0; i < straightSize; ++i) {
              theCards.push(checkForStraightFlush ? cardOfSuit(numericRanksPresent[highCardRank - i], highCardSuit) : numericRanksPresent[highCardRank - i][0]);
            }

            retVal = [{ handRank: HandRank.Straight, cardRank: highCardRank, theCards: theCards, hiddenMember: isMemberHidden(theCards) }];
          }
        }
      }

      if (retVal !== null) {
        if (cards.length > straightSize) {
          appendRemainingCards(retVal, cards);
        }

        return retVal;
      }

      return null;
    }

    function isThreeOfAKind(cards: SuitRank[]) {
      const threeOfAKind = n_of_a_Kind(cards, 3, []);
      if (threeOfAKind) {
        threeOfAKind[0].handRank = HandRank.Three_of_a_Kind;
        return appendRemainingCards(threeOfAKind, cards);
      }

      return null;
    }

    function isTwoPair(cards: SuitRank[]) {
      const pair1 = n_of_a_Kind(cards, 2, []);
      if (pair1) {
        const pair2 = n_of_a_Kind(cards, 2, [pair1[0].cardRank]);
        if (pair2) {
          pair1[0].handRank = HandRank.TwoPair;
          pair2[0].handRank = HandRank.TwoPair;
          pair1[0].hiddenMember = pair1[0].hiddenMember || pair2[0].hiddenMember;
          return appendRemainingCards(pair1.concat(pair2), cards);
        }
      }

      return null;
    }

    function isPair(cards: SuitRank[]): PokerRank[] {
      const pair = n_of_a_Kind(cards, 2, []);
      if (pair) {
        pair[0].handRank = HandRank.Pair;
        return appendRemainingCards(pair, cards);
      }

      return null;
    }

    function mustBeHighCard(cards: SuitRank[]): PokerRank[] {
      return appendRemainingCards([], cards);
    }

    const pokerHand =
      isRoyalFlush(cardsToCheck) ||
      isStraightFlush(cardsToCheck) ||
      isFourOfAKind(cardsToCheck) ||
      isFullHouse(cardsToCheck) ||
      isFlush(cardsToCheck) ||
      isStraight(cardsToCheck, 5, false) ||
      isThreeOfAKind(cardsToCheck) ||
      isTwoPair(cardsToCheck) ||
      isPair(cardsToCheck) ||
      mustBeHighCard(cardsToCheck);

    //  verify the "TheCards" array
    let ttlCards = 0;
    for (let h = 0; h < pokerHand.length; ++h) {
      ttlCards += pokerHand[h].theCards.length;
    }
    if (ttlCards !== cardsToCheck.length) {
      console.log(pokerHand);
      window.alert(`Expected ${cardsToCheck.length} cards returned but got ${ttlCards}`);
    }

    return pokerHand;
  }

  private cardsIn(pr: PokerRank[], n: number): SuitRank[] {
    let cards: SuitRank[] = [];
    let i = 0;
    while (i <= n) {
      const prs = this.pokerRanksConsumed(pr[i]);

      for (var j = 0; j < prs; ++j) {
        cards = cards.concat(pr[i + j].theCards);
      }

      i += prs;
    }

    return cards;
  }

  //  call with the output of RankHand()
  resolveHands(dealerHand: PokerRank[], playerHand: PokerRank[], playerFolded: boolean): HandResolution {
    const resolution: HandResolution = {
      dealerQualified: dealerHand[0].handRank > HandRank.HighCard,
      message: "Push",
      playerBest: playerHand[0],
      winner: HandWinner.Push,
      playerFolded: playerFolded,
      decider: null,
      winningCards: null,
      losingCards: null
    };

    for (let i = 0, cardsConsumed = 0; resolution.winner === HandWinner.Push && cardsConsumed < 5 && i < dealerHand.length && i < playerHand.length; ++i) {
      if (dealerHand[i].handRank > playerHand[i].handRank) {
        resolution.decider = dealerHand[i];
        resolution.winner = HandWinner.Dealer;
        resolution.winningCards = this.cardsIn(dealerHand, i);
        resolution.losingCards = this.cardsIn(playerHand, i);
        resolution.message = `Dealer’s ${this.pokerRankString(dealerHand[i])} Beats ${this.pokerRankString(playerHand[i])}`;
      } else if (dealerHand[i].handRank < playerHand[i].handRank) {
        resolution.decider = playerHand[i];
        resolution.winner = HandWinner.Player;
        resolution.winningCards = this.cardsIn(playerHand, i);
        resolution.losingCards = this.cardsIn(dealerHand, i);
        resolution.message = `${this.pokerRankString(playerHand[i])} Beats Dealer’s ${this.pokerRankString(dealerHand[i])}`;
      } else if (dealerHand[i].cardRank > playerHand[i].cardRank) {
        resolution.decider = dealerHand[i];
        resolution.winner = HandWinner.Dealer;
        resolution.winningCards = this.cardsIn(dealerHand, i);
        resolution.losingCards = this.cardsIn(playerHand, i);
        resolution.message = `Dealer’s ${this.displayPokerRank(dealerHand, i)} Beats ${this.displayPokerRank(playerHand, i)}`;
      } else if (dealerHand[i].cardRank < playerHand[i].cardRank) {
        resolution.decider = playerHand[i];
        resolution.winner = HandWinner.Player;
        resolution.winningCards = this.cardsIn(playerHand, i);
        resolution.losingCards = this.cardsIn(dealerHand, i);
        resolution.message = `${this.displayPokerRank(playerHand, i)} Beats Dealer’s ${this.displayPokerRank(dealerHand, i)}`;
      }

      cardsConsumed += Math.max(dealerHand[i].theCards.length, playerHand[i].theCards.length);
    }

    return resolution;
  }

  /*  Make the large raise according to the table at https://wizardofodds.com/games/ultimate-texas-hold-em/images/4x-strategy.png
      Basically:
        Pair of 3s or higher
        Ace-high
        King with 5 or above (2 or above if suited)
        Queen with 8 or above (6 or above if suited)
        Jaek with 10 (8 or above if suited)
   */
  makeLargeRaise(playerCards: SuitRank[]): boolean {
    const playerPRA = this.rankHand(playerCards);
    const pr1 = playerPRA[0];

    if (pr1.handRank === HandRank.Pair) return pr1.cardRank > Rank.Two;

    //  now we're looking at high cards
    const pr2 = playerPRA[1];
    const suited = pr1.theCards[0].suit === pr2.theCards[0].suit;

    if (pr1.cardRank === Rank.Ace) return true;
    if (pr1.cardRank === Rank.King && pr2.cardRank >= (suited ? Rank.Two : Rank.Five)) return true;
    if (pr1.cardRank === Rank.Queen && pr2.cardRank >= (suited ? Rank.Six : Rank.Eight)) return true;
    if (pr1.cardRank === Rank.Jack && pr2.cardRank >= (suited ? Rank.Eight : Rank.Ten)) return true;
    return false;
  }

  /*  Make the medium raise with:
        Two pair or better.
        Hidden pair, except pocket deuces.
        Four to a flush including a hidden 10 or better to that flush
   */
  makeMediumRaise(playerCards: SuitRank[], flopCards: SuitRank[]): boolean {
    const playerPRA = this.rankHand(playerCards, flopCards);
    const pr1 = playerPRA[0];

    function fourToAFlush(): boolean {
      let cards: SuitRank[] = [];
      playerPRA.forEach(pr => (cards = cards.concat(pr.theCards)));
      const suitWithFour = [Suit.Clubs, Suit.Diamonds, Suit.Hearts, Suit.Spades].filter(s => cards.filter(c => c.suit === s).length === 4)[0];
      return suitWithFour !== undefined && playerCards.some(c => c.suit === suitWithFour && c.rank >= Rank.Ten);
    }

    const pocketDeuces = playerCards[0].rank === Rank.Two && playerCards[1].rank === Rank.Two;

    if (pr1.hiddenMember && (pr1.handRank >= HandRank.TwoPair || (pr1.handRank === HandRank.Pair && !pocketDeuces))) {
      return true;
    }

    return fourToAFlush();
  }

  /*  Make the medium raise with:
        Hidden pair or better.
        Less than 21 dealer outs beat you.
   */
  makeSmallRaise(playerCards: SuitRank[], flopCards: SuitRank[], riverCards: SuitRank[], playerFolded: boolean): boolean {
    const playerPRA = this.rankHand(playerCards, flopCards, riverCards);

    //  quick exit: the the player has a hidden pair or better
    if (playerPRA[0].handRank >= HandRank.Pair && playerPRA[0].hiddenMember) {
      return true;
    }

    const communityPRA = this.rankHand([], flopCards, riverCards);
    const resolution = this.resolveHands(communityPRA, playerPRA, playerFolded);

    const lastCardRank = (): Rank => {
      let r: Rank;

      for (let i = 0, nCards = 0; i < playerPRA.length && nCards < 5; i += this.pokerRanksConsumed(playerPRA[i])) {
        r = playerPRA[i].cardRank;
        nCards += this.cardsConsumed(playerPRA[i]);
      }

      return r;
    };

    const computeDealerOuts = (): number => {
      const communityCards = flopCards.concat(riverCards);
      const communityRanks: Rank[] = [];
      communityCards.forEach(c => {
        if (!communityRanks.includes(c.rank)) communityRanks.push(c.rank);
      });

      const unknownCards: SuitRank[] = new Deck().cardsWithout(playerCards.concat(communityCards));

      //  all the unknown cards that match the community ranks make pairs, trips, or quads
      const pairUp = +unknownCards.filter(c => communityRanks.includes(c.rank)).length;
      console.log(`${pairUp} from matching community ranks`);

      const lastCardHighRank = Math.max(playerCards[0].rank, playerCards[1].rank, lastCardRank()) as Rank;
      const beatPlayerHigh = unknownCards.filter(c => c.rank > lastCardHighRank).length;
      console.log(`${beatPlayerHigh} from beating high card of ${Rank[lastCardHighRank]}`);

      console.log(`${pairUp + beatPlayerHigh} total dealer outs`);

      return pairUp + beatPlayerHigh;
    };

    console.log(`Player vs. community says winner is ${HandWinner[resolution.winner].replace("Dealer", "Community")}`);
    if (resolution.decider) console.log(`The decider is ${this.pokerRankString(resolution.decider)}`);

    switch (resolution.winner) {
      case HandWinner.Dealer:
        alert("Very odd that the community wins!");
        return false;
      case HandWinner.Push:
        console.log("Player and community push means all the best cards are in the community");

        //  bet if the community hand takes 5 cards (we'll likely push; so let's not lose our money)
        if (this.cardsConsumed(communityPRA[0]) === 5) {
          // computeDealerOuts();
          return true;
        }

        return computeDealerOuts() < 21;
      case HandWinner.Player:
        if (resolution.decider.handRank > HandRank.HighCard) {
          console.log(`Winner is player and decider is ${this.pokerRankString(resolution.decider)}`);
          return true;
        }

        return computeDealerOuts() < 21;
    }
  }
}
