import * as React from "react";

export interface LayerProps {
  layerName: string;
  style?: React.CSSProperties;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
}

export class Layer extends React.Component<LayerProps, {}> {
  render() {
    return (
      <div className={`layer ${this.props.layerName}`} style={this.props.style} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}
