import * as React from "react";
import { Layer } from "../Home/Layer";
import { Card, SuitRank } from "./Card";
import "./CardBlock.css";
import { CardHolder } from "./CardHolder";

export interface CardBlockProps {
  cards: SuitRank[];
  faceUp: boolean;
  winningCards: SuitRank[];
  losingCards: SuitRank[];
  blockName: string;
  label?: string;
  folded?: boolean;
}

export class CardBlock extends React.Component<CardBlockProps, {}> {
  componentDidMount() {
    this.insertAnimationStyles();
    // window.addEventListener("resize", this.insertAnimationStyles);
    window.addEventListener("home-showing", this.insertAnimationStyles);
  }

  private insertAnimationStyles = (): void => {
    const scale = (window as any).homeScale;
    const cardBlock = document.querySelector<HTMLElement>(`.card-block.${this.props.blockName}`);
    if (!cardBlock) debugger;
    const cardHolders = cardBlock.querySelectorAll<HTMLElement>(".card-holder");

    const rules: string[] = [];
    cardHolders.forEach((holder, i) => {
      const sel = `div.card.${this.props.blockName}-${i + 1}`;
      const box = holder.getBoundingClientRect();
      let delay = 100 * (cardHolders.length - 1 - i);
      if (this.props.blockName === "river-cards") {
        delay = 100;
      }
      rules.push(`${sel}.dealt { left: ${box.left / scale}px; top: ${box.top / scale}px; transform: rotate(0); transition-delay: ${delay}ms }`);
    });

    let style = document.getElementById(`${this.props.blockName}-animation-styles`);
    if (!style) {
      style = document.createElement("style");
      style.id = `${this.props.blockName}-animation-styles`;
      document.head.appendChild(style);
    }
    style.innerHTML = rules.join("\n");
  };

  render() {
    return (
      <>
        <div className={this.props.label ? "card-block-inline" : null}>
          <div className={`card-block ${this.props.blockName}`}>
            <CardHolder n={this.props.cards.length} groupName={this.props.blockName} />
          </div>
          {this.props.label && (
            <>
              <div className="card-block-bracket" />
              <div className="card-block-label">{this.props.label}</div>
            </>
          )}
        </div>
        <Layer layerName={`cards ${this.props.blockName}`}>
          {this.props.cards.map((sr, i) => (
            <Card
              key={`${this.props.blockName}-${i + 1}`}
              {...sr}
              classNameExtra={`${this.props.blockName}-${i + 1}`}
              faceUp={this.props.faceUp}
              winningCards={this.props.winningCards}
              losingCards={this.props.losingCards}
              folded={this.props.folded}
            />
          ))}
        </Layer>
      </>
    );
  }
}
