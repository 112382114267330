import * as React from "react";
import cba from "./art/Card-Back-Art.png";
import jc from "./art/JC.png";
import jd from "./art/JD.png";
import jh from "./art/JH.png";
import js from "./art/JS.png";
import kc from "./art/KC.png";
import kd from "./art/KD.png";
import kh from "./art/KH.png";
import ks from "./art/KS.png";
import qc from "./art/QC.png";
import qd from "./art/QD.png";
import qh from "./art/QH.png";
import qs from "./art/QS.png";
import "./Card.css";
import { PokerHand } from "./PokerHand";

export enum Suit {
  Hearts = 1,
  Spades,
  Clubs,
  Diamonds
}

export enum Rank {
  Two = 2,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Jack,
  Queen,
  King,
  Ace
}

export interface SuitRank {
  suit: Suit;
  rank: Rank;
  suitRankString: string;
}

export interface CardProps extends SuitRank {
  faceUp: boolean;
  winningCards: SuitRank[];
  losingCards: SuitRank[];
  classNameExtra: string;
  folded?: boolean;
}

class CardState {
  showFace: boolean = false;
}

export class Card extends React.Component<CardProps, CardState> {
  constructor(props: CardProps) {
    super(props);
    this.state = new CardState();
  }

  get suitSymbol(): string {
    switch (this.props.suit) {
      case Suit.Clubs:
        return "C";
      case Suit.Diamonds:
        return "4";
      case Suit.Hearts:
        return "3";
      case Suit.Spades:
        return "S";
    }

    return PokerHand.getSuitSymbol(this.props);
  }

  get rankSymbol(): string {
    return PokerHand.getRankSymbol(this.props);
  }

  get isFace(): boolean {
    switch (this.props.rank) {
      case Rank.King:
      case Rank.Queen:
      case Rank.Jack:
        return true;
      default:
        return false;
    }
  }

  get faceSouce(): string {
    switch (this.props.rank) {
      case Rank.Jack:
        switch (this.props.suit) {
          case Suit.Clubs:
            return jc;
          case Suit.Diamonds:
            return jd;
          case Suit.Hearts:
            return jh;
          case Suit.Spades:
            return js;
        }
        break;
      case Rank.King:
        switch (this.props.suit) {
          case Suit.Clubs:
            return kc;
          case Suit.Diamonds:
            return kd;
          case Suit.Hearts:
            return kh;
          case Suit.Spades:
            return ks;
        }
        break;
      case Rank.Queen:
        switch (this.props.suit) {
          case Suit.Clubs:
            return qc;
          case Suit.Diamonds:
            return qd;
          case Suit.Hearts:
            return qh;
          case Suit.Spades:
            return qs;
        }
        break;
    }

    return null;
  }

  get highlightClass(): string {
    if (!this.state.showFace) return "";

    let winningOrLosing: string = this.props.folded ? "folded" : "";

    if (this.props.winningCards?.some(c => c.rank === this.props.rank && c.suit === this.props.suit)) {
      winningOrLosing += " winning";
    }

    if (this.props.losingCards?.some(c => c.rank === this.props.rank && c.suit === this.props.suit)) {
      winningOrLosing += " losing";
    }

    if (winningOrLosing) {
      return winningOrLosing;
    }

    if (this.props.winningCards || this.props.losingCards) {
      return "not-involved";
    }

    return "";
  }

  render() {
    if (this.props.faceUp !== this.state.showFace) {
      window.setTimeout(() => this.setState({ showFace: this.props.faceUp }), this.props.faceUp ? 500 : 0);
    }

    return (
      <div className={`card ${this.props.classNameExtra} ${this.highlightClass} ${this.props.faceUp ? "dealt" : ""}`}>
        <div hidden={!this.state.showFace} className={`face`} data-suit={Suit[this.props.suit]} data-rank={Rank[this.props.rank]}>
          <span className="rank">{this.rankSymbol}</span>
          <span className="suit1">{this.suitSymbol}</span>
          {!this.isFace && <span className="suit2">{this.suitSymbol}</span>}
          {this.isFace && (
            <>
              <img className="face-pic" alt={`${Rank[this.props.rank]} of ${Suit[this.props.suit]}`} src={this.faceSouce} />
              <span className="face-suit">{this.suitSymbol}</span>
            </>
          )}
        </div>
        <div style={this.state.showFace ? { opacity: 0 } : { backgroundImage: `url(${cba})` }} className="back"></div>
      </div>
    );
  }
}
