import * as React from "react";
import { PokerHand } from "../Cards/PokerHand";
import { Bets, TheBets } from "../Home/Home";
import { Layer } from "../Home/Layer";
import { PayMultiples, PayOuts } from "../Home/PayMaster";
import { Bet, BetOrPay, StackOrTaken } from "./Bet";
import "./BetBlock.css";

export interface BetBlockProps {
  dealerNotQualified: boolean;
  bets: Bets;
  clearBet: (which: TheBets) => void;
  payouts: PayOuts;
  tripsPayMultiples: PayMultiples;
  blindPayMultiples: PayMultiples;
  showPayoutTables: boolean;
}

enum DrawTakenState {
  Normal,
  AllStack,
  Ignore
}

class BetBlockState {
  drawTakenState: DrawTakenState = DrawTakenState.Normal;
}

export class BetBlock extends React.Component<BetBlockProps, BetBlockState> {
  constructor(props: BetBlockProps) {
    super(props);
    this.state = new BetBlockState();
  }

  componentDidMount() {
    this.insertAnimationStyles();
    // window.addEventListener("resize", this.insertAnimationStyles);
    window.addEventListener("home-showing", this.insertAnimationStyles);
  }

  private insertAnimationStyles = (): void => {
    const scale = (window as any).homeScale;
    const betBlock = document.querySelector<HTMLElement>(".bet-block");
    const circles = betBlock.querySelectorAll<HTMLElement>(".bet-circle, .pay-circle");
    const rules: string[] = [];

    circles.forEach(circle => {
      const betOrPay = circle.classList[0].split("-")[0];
      const betName = circle.classList[1];
      const circleBox = circle.getBoundingClientRect();
      const circleWidth = circleBox.width / scale;
      const circleHeight = circleBox.height / scale;
      const circleLeft = circleBox.left / scale;
      const circleTop = circleBox.top / scale;

      const fudgeX = 5;
      const fudgeY = 5;

      // const div = document.createElement("div");
      // div.style.left = `${circleBox.left - fudgeX}px`;
      // div.style.top = `${circleBox.top}px`;
      // div.style.width = `${circleBox.width}px`;
      // div.style.height = `${circleBox.height}px`;
      // div.style.backgroundColor = "rgba(255, 255, 255, 0.2)";
      // div.style.position = "absolute";
      // app.appendChild(div);

      rules.push(`.bet.${betOrPay}-${betName}.stack { left: ${-circleWidth}px; top: calc(100% + ${circleHeight}px); }`);
      rules.push(
        `.bet.${betOrPay}-${betName}.taken { left: ${-circleWidth}px; top: ${-circleHeight}px; transition-duration: 800ms; transition-delay: 400ms; }`
      );
      rules.push(`.bet.${betOrPay}-${betName}.table { left: ${circleLeft + fudgeX}px; top: ${circleTop + fudgeY}px; }`);
    });

    const antePayCircleBox = betBlock.querySelector<HTMLElement>(".pay-circle.ante").getBoundingClientRect();
    rules.push(`.trips-pay-table { left: ${antePayCircleBox.right / scale - 10}px; top: ${(antePayCircleBox.top + antePayCircleBox.height / 2) / scale}px }`);
    const blindPayCircleBox = betBlock.querySelector<HTMLElement>(".pay-circle.blind").getBoundingClientRect();
    rules.push(`.blind-pay-table { left: ${blindPayCircleBox.left / scale + 10}px; top: ${(blindPayCircleBox.top + blindPayCircleBox.height / 2) / scale}px }`);

    let style = document.getElementById("bet-block-animation-styles");
    if (!style) {
      style = document.createElement("style");
      style.id = "bet-block-animation-styles";
      document.head.appendChild(style);
    }
    style.innerHTML = rules.join("\n");
  };

  private setOrResetTaken = (): void => {
    if (this.props.payouts) {
      if (this.state.drawTakenState === DrawTakenState.Normal) {
        // console.log("BetBlock: setting drawTakenState to AllStack");
        this.setState({ drawTakenState: DrawTakenState.AllStack });
      } else if (this.state.drawTakenState === DrawTakenState.AllStack) {
        // console.log("BetBlock: setting drawTakenState to Ignore");
        this.setState({ drawTakenState: DrawTakenState.Ignore });
      }
    } else if (this.state.drawTakenState !== DrawTakenState.Normal) {
      // console.log("BetBlock: setting drawTakenState to Normal");
      this.setState({ drawTakenState: DrawTakenState.Normal });
    }
  };

  private tripsPayTable = (): JSX.Element => {
    return (
      <div className="pay-table trips-pay-table">
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>Trips Payouts</td>
            </tr>
            {this.props.tripsPayMultiples.map(tpm => (
              <tr key={tpm.hr}>
                <td>{PokerHand.handRankString(tpm.hr)}</td>
                <td>{tpm.m} to 1</td>
              </tr>
            ))}
            <tr>
              <td colSpan={2}>Trips pay win, lose or fold</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  private blindPayTable = (): JSX.Element => {
    return (
      <div className="pay-table blind-pay-table">
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>Blind Payouts</td>
            </tr>
            {this.props.blindPayMultiples.map(tpm => (
              <tr key={tpm.hr}>
                <td>{PokerHand.handRankString(tpm.hr)}</td>
                <td>
                  {tpm.m === 1.5 ? 3 : tpm.m} to {tpm.m === 1.5 ? 2 : 1}
                </td>
              </tr>
            ))}
            <tr>
              <td>All other</td>
              <td>Push</td>
            </tr>
            <tr>
              <td colSpan={2}>Blind pays only on win</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    window.setTimeout(this.setOrResetTaken, 1500);

    const betStackOrTaken = this.props.payouts && this.state.drawTakenState === DrawTakenState.Normal ? StackOrTaken.taken : StackOrTaken.stack;
    const payoutStackOrTaken = !this.props.payouts && this.state.drawTakenState === DrawTakenState.Normal ? StackOrTaken.taken : StackOrTaken.stack;
    // console.log(`Rendering bet block with stackOrTaken = ${betStackOrTaken}`);

    return (
      <div className={this.props.dealerNotQualified ? "dealer-not-qualified" : null}>
        <div className="bet-block">
          <div className="bet-row trips-row">
            <div className="pay-circle trips" />
            <div className="bet-stack">
              <div className="bet-diamond" />
              <div className="bet-circle trips">Trips</div>
            </div>
            <div className="bet-equals" />
            {/* <div className="bet-circle progressive"> </div> */}
          </div>
          <div className="bet-row ante-row">
            <div className="pay-circle ante" />
            <div className="bet-circle ante">Ante</div>
            <div className="bet-equals">=</div>
            <div className="bet-circle blind">Blind</div>
            <div className="pay-circle blind" />
          </div>
          <div className="bet-row play-row">
            <div className="pay-circle play" />
            <div className="bet-circle play">Play</div>
          </div>
        </div>
        {this.props.showPayoutTables && (
          <Layer layerName="pay-tables">
            {this.tripsPayTable()}
            {this.blindPayTable()}
          </Layer>
        )}
        <Layer layerName="bets">
          <Bet value={this.props.payouts?.trips} stackOrTaken={payoutStackOrTaken} betOrPay={BetOrPay.pay} betName="trips" />
          <Bet
            value={this.props.bets.trips}
            betClick={() => this.props.clearBet(TheBets.trips)}
            stackOrTaken={betStackOrTaken}
            betOrPay={BetOrPay.bet}
            betName="trips"
          />
          <Bet value={this.props.payouts?.ante} stackOrTaken={payoutStackOrTaken} betOrPay={BetOrPay.pay} betName="ante" />
          <Bet
            value={this.props.bets.ante}
            betClick={() => this.props.clearBet(TheBets.ante)}
            stackOrTaken={betStackOrTaken}
            betOrPay={BetOrPay.bet}
            betName="ante"
          />
          <Bet value={this.props.payouts?.blind} stackOrTaken={payoutStackOrTaken} betOrPay={BetOrPay.pay} betName="blind" />
          <Bet
            value={this.props.bets.blind}
            betClick={() => this.props.clearBet(TheBets.blind)}
            stackOrTaken={betStackOrTaken}
            betOrPay={BetOrPay.bet}
            betName="blind"
          />
          <Bet value={this.props.payouts?.play} stackOrTaken={payoutStackOrTaken} betOrPay={BetOrPay.pay} betName="play" />
          <Bet
            value={this.props.bets.play}
            betClick={() => this.props.clearBet(TheBets.play)}
            stackOrTaken={betStackOrTaken}
            betOrPay={BetOrPay.bet}
            betName="play"
          />
        </Layer>
      </div>
    );
  }
}
