import * as React from "react";

export interface CardHolderProps {
  n: number;
  groupName: string;
}

export class CardHolder extends React.Component<CardHolderProps, {}> {
  render() {
    const holders: string[] = [];
    for (let i = this.props.n; i > 0; --i) {
      holders.push(`${this.props.groupName}-${i}`);
    }

    return (
      <>
        {holders.map(h => (
          <div key={h} className={`card-holder ${h}`}></div>
        ))}
      </>
    );
  }
}
