import * as React from "react";
import { GameState } from "../Home/Home";
import "./ButtonBlock.css";

export enum Buttons {
  TripsPlus5,
  BetPlus5,
  Deal,
  Bet,
  CheckOrFold,
  NextHand,
  Bet3X
}

export interface ButtonBlockProps {
  gameState: GameState;
  newHand: () => void;
  advanceGameState: (nextState: GameState) => void;
  raiseTrips: (raise: number) => void;
  raiseAnteAndBlind: (raise: number) => void;
  betPlay: (multiple: number) => void;
  noDeal: boolean;
  fold: () => void;
  animating: boolean;
  defaultButton: Buttons;
}

export class ButtonBlock extends React.Component<ButtonBlockProps, {}> {
  private classNameFor(b: Buttons): string {
    const cn = `${this.props.animating ? "animating" : ""} ${this.props.defaultButton === b ? "default" : ""}`.trim();
    return cn || null;
  }

  render() {
    return (
      <div className="button-block">
        {this.props.gameState === GameState.NoBet && (
          <>
            <button className={this.classNameFor(Buttons.BetPlus5)} type="button" onClick={() => this.props.raiseAnteAndBlind(5)}>
              Bet +5
            </button>
            <button className={this.classNameFor(Buttons.TripsPlus5)} type="button" onClick={() => this.props.raiseTrips(5)}>
              Trips +5
            </button>
            <button className={this.classNameFor(Buttons.Deal)} type="button" disabled={this.props.noDeal} onClick={() => this.props.advanceGameState(GameState.PlayerCardsDealt)}>
              Deal
            </button>
          </>
        )}
        {this.props.gameState === GameState.PlayerCardsDealt && (
          <>
            <button
              className={this.classNameFor(Buttons.Bet)}
              type="button"
              onClick={() => {
                this.props.betPlay(4);
                this.props.advanceGameState(GameState.DealerShown);
              }}
            >
              Bet 4X
            </button>
            <button
              className={this.classNameFor(Buttons.Bet3X)}
              type="button"
              onClick={() => {
                this.props.betPlay(3);
                this.props.advanceGameState(GameState.DealerShown);
              }}
            >
              Bet 3X
            </button>
            <button className={this.classNameFor(Buttons.CheckOrFold)} type="button" onClick={() => this.props.advanceGameState(GameState.FlopShown)}>
              Check
            </button>
          </>
        )}
        {this.props.gameState === GameState.FlopShown && (
          <>
            <button
              className={this.classNameFor(Buttons.Bet)}
              type="button"
              onClick={() => {
                this.props.betPlay(2);
                this.props.advanceGameState(GameState.DealerShown);
              }}
            >
              Bet 2X
            </button>
            <button className={this.classNameFor(Buttons.CheckOrFold)} type="button" onClick={() => this.props.advanceGameState(GameState.RiverShown)}>
              Check
            </button>
          </>
        )}
        {this.props.gameState === GameState.RiverShown && (
          <>
            <button
              className={this.classNameFor(Buttons.Bet)}
              type="button"
              onClick={() => {
                this.props.betPlay(1);
                this.props.advanceGameState(GameState.DealerShown);
              }}
            >
              Bet 1X
            </button>
            <button className={this.classNameFor(Buttons.CheckOrFold)} type="button" onClick={this.props.fold}>
              Fold
            </button>
          </>
        )}
        {this.props.gameState === GameState.DealerShown && (
          <button className={this.classNameFor(Buttons.NextHand)} type="button" onClick={this.props.newHand}>
            Next Hand
          </button>
        )}
      </div>
    );
  }
}
