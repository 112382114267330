import * as React from "react";
import { Banker } from "../Home/Banker";

export interface ChipsStackProps {
  bank: Banker;
  onTable: number;
}

class ChipsStackState {
  balance: number;

  constructor(props: ChipsStackProps) {
    this.balance = props.bank.balance - props.onTable;
  }
}

export class ChipsStack extends React.Component<ChipsStackProps, ChipsStackState> {
  constructor(props: ChipsStackProps) {
    super(props);
    this.state = new ChipsStackState(props);
  }

  private balanceDisplay(): JSX.Element {
    let balance = this.state.balance;

    if (balance % 1 === 0.5) {
      balance = balance < 0 ? Math.ceil(balance) : Math.floor(balance);
      return (
        <>
          {Math.floor(balance)}
          <span className="half">½</span>
        </>
      );
    }

    return <>{balance}</>;
  }

  private updateStateBalance = () => {
    const newBalance = this.props.bank.balance - this.props.onTable;

    if (this.state.balance < newBalance) {
      if (newBalance - this.state.balance > 5) {
        this.setState({ balance: this.state.balance + 5 });
      } else {
        this.setState({ balance: newBalance });
      }
    } else if (newBalance < this.state.balance) {
      if (this.state.balance - newBalance > 5) {
        this.setState({ balance: this.state.balance - 5 });
      } else {
        this.setState({ balance: newBalance });
      }
    }
  };

  private maxWidth = 0;

  private setHeight = () => {
    const chipsStack = document.querySelector<HTMLDivElement>(".bet.chips-stack");

    if (chipsStack.offsetWidth > this.maxWidth) {
      this.maxWidth = chipsStack.offsetWidth;
      chipsStack.style.minWidth = `${this.maxWidth}px`;
      chipsStack.style.height = `${this.maxWidth}px`;
      chipsStack.style.lineHeight = `${this.maxWidth - 6}px`;
    }
  };

  private onStackClick = () => {
    this.setState({ balance: this.props.bank.reset() });
  };

  render() {
    window.setTimeout(this.setHeight, 0);

    if (this.state.balance !== this.props.bank.balance - this.props.onTable) {
      window.requestAnimationFrame(this.updateStateBalance);
    }

    return (
      <div className="bet chips-stack" onClick={this.onStackClick}>
        {this.balanceDisplay()}
      </div>
    );
  }
}
